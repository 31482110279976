import appIcon from './../res/favicomatic/apple-touch-icon-144x144.png'
import './accordion.scss'
import track from './../track.js'

class AccordionItem{
	constructor(props){
		this.title = props.title
	}
}

export default class Accordion{
	constructor(props){
		this.items = props.items

		this.div = document.createElement('div')
		this.div.className = 'accordion'

		this.items.forEach((item, index)=>{
			let itemDiv = document.createElement('div')
			itemDiv.className = `accordion-item ${(index === 0) ? 'active' : ''}`

			itemDiv.innerHTML = `
				<h3 class="title">${item.title}</h3>
				<ul class="content"></ul>
			`

			itemDiv.getElementsByClassName('title')[0].onclick = ()=>{
				this.disableAllItems()
				itemDiv.classList.toggle('active')
			}

			item.items.forEach((contentLine)=>{
				let itemLine = document.createElement('li')
				itemLine.className = 'content-line'
				itemLine.innerHTML = contentLine

				itemDiv.getElementsByClassName('content')[0].appendChild(itemLine)
			})

			this.div.appendChild(itemDiv)
		})

		return this.div
	}

	disableAllItems(){
		console.log(this.div.getElementsByClassName('accordion-item'))
		let itemsLength = this.div.getElementsByClassName('accordion-item').length

		for (let i = 0; i < itemsLength; i++) {
			this.div.getElementsByClassName('accordion-item')[i].classList.remove('active')
		}
		// this.div.getElementsByClassName('accordion-item').forEach((el)=>{
		// 	el.classList.remove('active')
		// })
	}
}