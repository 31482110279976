{
  "name": "pixie-space-shooter",
  "productName": "Space Warz",
  "version": "0.7.0",
  "scripts": {
    "forever": "pm2 start --name spaceWarz npm -- run serveProd",
    "serve": "node ./src/serve.mjs --dev=1",
    "serveProd": "node ./src/serve.mjs",
    "dev": "npm run lintLevels && parcel ./src/index.html --no-source-maps --no-hmr -p 5555 & npm run serve",
    "devPublic": "rm -rf ./dist/* && parcel ./src/public-web/index.html --no-source-maps --no-hmr -p 5556 & npm run serve",
    "build-game": "npm run lintLevels && rm -rf ./game-web-build && parcel build --no-source-maps ./src/index.html --dist-dir ./game-web-build --public-url ./ && cp -a src/electron/. game-web-build/",
    "build-mac": "export DEBUG=electron-osx-sign* && electron-builder mac",
    "build-apps": "electron-builder -mwl",
    "build-public-web": "npm run clear && rm -rf ./public-web-build/* && parcel build ./src/public-web/index.html --dist-dir ./public-web-build --public-url ./ --no-cache",
    "assemble-release": "node ./scripts/assemble-release.js",
    "release": "npm run build-game && npm run build-apps && node ./scripts/collect-build-links.js && npm run build-public-web && npm run assemble-release",
    "clear": "rm -rf ./.cache && rm -rf ./.parcel-cache && rm -rf ./public-web-build && rm -rf ./game-web-build && rm -rf ./game-app-builds",
    "backup": "node ./scripts/backup-scores.js",
    "update": "git pull && npm run build-game",
    "lintLevels": "jsonlint -q ./src/data.json",
    "levelEditor": "parcel ./lvl-editor/index.html --no-source-maps --no-hmr -p 2345"
  },
  "author": "Oto Inša",
  "license": "ISC",
  "dependencies": {
    "@babel/plugin-syntax-import-assertions": "^7.22.5",
    "@pixi/filter-advanced-bloom": "^4.1.5",
    "@pixi/filter-ascii": "^2.5.0",
    "@pixi/filter-shockwave": "^4.1.5",
    "axios": "^0.18.0",
    "crypto-js": "^3.1.9-1",
    "easytimer.js": "^3.0.0",
    "gamepad-listener": "^0.4.0",
    "howler": "^2.1.0",
    "jsmediatags": "^3.8.1",
    "mousetrap": "^1.6.1",
    "nipplejs": "^0.8.0",
    "pixi-filters": "^2.6.0",
    "pixi-sound": "^2.0.4",
    "pixi.js": "^4.8.9",
    "pubsub-js": "^1.7.0"
  },
  "devDependencies": {
    "@babel/core": "^7.16.7",
    "@babel/plugin-proposal-class-properties": "^7.16.7",
    "@babel/plugin-proposal-decorators": "^7.16.7",
    "@babel/plugin-proposal-object-rest-spread": "^7.16.7",
    "@parcel/transformer-sass": "^2.9.3",
    "adm-zip": "^0.4.7",
    "axios": "^0.18.0",
    "body-parser": "^1.18.3",
    "cli-table": "^0.3.1",
    "colors": "^1.2.1",
    "debounce-decorator": "^1.0.6",
    "del": "^3.0.0",
    "easy-zip": "0.0.4",
    "electron": "^17.0.0",
    "electron-builder": "^22.14.13",
    "electron-packager": "^15.4.0",
    "events": "^3.3.0",
    "express": "^4.16.3",
    "globby": "^8.0.1",
    "googleapis": "^30.0.0",
    "jest": "^23.6.0",
    "jsonlint": "^1.6.3",
    "lokijs": "^1.5.5",
    "node-sass": "^7.0.1",
    "ora": "^3.4.0",
    "parcel": "^2.9.3",
    "path-browserify": "^1.0.1",
    "process": "^0.11.10",
    "punycode": "^1.4.1",
    "querystring-es3": "^0.2.1",
    "rimraf": "^2.6.2",
    "yodasay": "^1.1.6",
    "zip-dir": "^1.0.2"
  },
  "build": {
    "directories": {
      "output": "./game-app-builds",
      "app": "./game-web-build"
    },
    "mac": {
      "appId": "xyz.digital-ninja.Space-Warz-macOS",
      "category": "public.app-category.games",
      "entitlements": "./certs/entitlements.mas.plist",
      "icon": "./src/res/icon.icns",
      "gatekeeperAssess": false,
      "target": "pkg"
    },
    "mas": {
      "appId": "xyz.digital-ninja.Space-Warz-macOS",
      "category": "public.app-category.games",
      "entitlements": "./certs/entitlements.mas.plist",
      "type": "distribution",
      "target": "pkg"
    },
    "win": {
      "target": "zip",
      "icon": "./src/res/win.ico"
    },
    "linux": {
      "target": "zip",
      "icon": "./src/res/icon.icns"
    }
  },
  "built": [
    {
      "fileName": "Space Warz-0.5.0-mac.zip",
      "platform": "macOS",
      "size": 101.99,
      "date": "2018-10-09T10:06:09.000Z"
    },
    {
      "fileName": "Space Warz-0.5.0-win.zip",
      "platform": "Windows",
      "size": 71.33,
      "date": "2018-10-09T10:02:36.000Z"
    }
  ],
  "cryptoWallets": [
    {
      "coin": "XRP (BEP20)",
      "address": "0x1122b3023f6dafabbcffe3bf386484792db526ae"
    },
    {
      "coin": "USDT (TRC20)",
      "address": "TQe6CFf6nkr6HRd4vNwjz2e1Kn68KJ8mLa"
    },
    {
      "coin": "Etherum (BEP20)",
      "address": "0x1122b3023f6dafabbcffe3bf386484792db526ae"
    },
    {
      "coin": "Bitcoin",
      "address": "36oaV5U2yYiEH6is1kWv9ZoE8YruPpPhjg"
    }
  ],
  "release-notes": [
    {
      "title": "0.7.0 - Galactic",
      "items": [
        "Implemented various bug fixes for the browser environment.",
        "Updated the online 'try-it-in-browser' version, making it available to play again.",
        "Made various public web updates, including a Spotify embed with music, replacement of gameplay video with screenshots, and several styling updates.",
        "Now displaying version code on the pause/settings screen; desktop environments also start the game paused.",
        "Added a new in-game backdrop image optimized for higher resolution displays and introduced floating rock objects.",
        "Developed a new level editor under the hood to generate levels more efficiently."
      ]
    },
    {
      "title": "0.6.0 - Rattu",
      "items": [
        "This release marks the first store release for Android and iOS with touch controls, as well as some very important performance improvements underneath.",
        "Fresh eye candy - Scoreboard visual improvements, on-screen pause button, ship skins, red enemies visually react to damage.",
        "Sound design - All-new soundtracks by Simple Dimple, ricochet sounds, stereo-mapped shooting sounds.",
        "Level design - Few level changes to match new soundtracks, boss level (10).",
        "Under the hood - Bullet sprite recycling, better asset management, gamepad support (Xbox 360 controller tested), profanity check for high scores."
      ]
    },
    {
      "title": "0.5.0 - Solo",
      "items": [
        "New powerups - Triple shooting mode, shield powerup.",
        "Sound design - 2 new shooting sounds for shotgun and triple shooting modes.",
        "Level design - 8th level.",
        "Fresh eye candy - Ship shield animation, async loader in health bar.",
        "Public web now has a PayPal donation link.",
        "Bug fixes and other improvements."
      ]
    },
    {
      "title": "0.4.0 - S2P2",
      "items": [
        "High Scores - Your name on the front page.",
        "Bug fixes, improvements."
      ]
    },
    {
      "title": "0.3.0 - Teedoer",
      "items": [
        "Level design - 7th level.",
        "Introduction of powerups - Shotgun mode, slomo, health packs.",
        "Fresh eye candy - A nicer load/splash screen.",
        "Performance improvements and bug fixes."
      ]
    },
    {
      "title": "0.2.0 - Jarsie",
      "items": [
        "Level design - 6th level.",
        "Shortcuts - Pause, resume (P), and restart game (R) with keyboard shortcuts; toggle sound pressing the (M) key.",
        "Input - Rewrote keyboard class to be more extendable; fixed unintentional handling of system shortcuts.",
        "New enemy type - Evil Mothership spawns lower-class enemies.",
        "Fresh eye candy - Animated sprites on enemy deaths, health bars for more serious enemies, animated game loading bar/health bar on entry, use of a familiar font face.",
        "Public web - Responsive, better release notes, crypto donation links have icons now."
      ]
    },
    {
      "title": "0.1.0 - Chewie",
      "items": [
        "Level design - 5 levels, sound loops to go with them, and easy-to-use JSON structure that defines levels.",
        "Sound design - Reactive sound effects for bullet shots, enemy deaths, player ship taking damage, game over, etc.",
        "Game lifecycles - Game over, game complete, restart game, and others.",
        "Ship HUD - Health bar, level info, accuracy/kills statistics, level announcement.",
        "Input - Keyboard bindings for controlling the player's ship.",
        "New enemy type - Red Drones.",
        "Scoring - Points calculation at the end of the game.",
        "Fresh eye candy - Shockwave effect when a certain type of enemy is destroyed, background with stars parallax effect.",
        "Collisions - Enemies cause damage when you let them past the viewport; bullets are garbage collected when they hit past the edge of the screen.",
        "Public web - Download releases, YouTube video with gameplay preview, crypto donation links, analytics."
      ]
    }
  ]
}