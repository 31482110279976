import './crypto-block.scss'
import copyIcon from './copy.svg'
import track from './../track.js'

// import etherumLogo from './etherum.svg'
// import bitcoinLogo from './bitcoin.svg'
// import litecoinLogo from './litecoin.svg'

// let logoMap = {
// 	'Etherum': etherumLogo,
// 	'Bitcoin': bitcoinLogo,
// 	'Litecoin': litecoinLogo
// }

export default class CryptoBlock{
	constructor(props){
		this.props = props

		this.div = document.createElement('div')
		this.div.className = 'crypto-block'

		this.div.innerHTML = `
			<div class="top ${props.coin}">
				<span class="title">
					<h3>${props.coin}</h3>
				</span>
				<img src="${copyIcon}" class='copy-icon' />

				<br class="clearfix" />
			</div>

			<div class="address">
				${props.address}
			</div>
		`

		this.div.onclick = (e)=>{
			this.onClicked(e)
		}

		return this.div
	}

	onClicked(e){
		track('Took coin address', {
			coin: this.props.coin
		})

		let el = e.currentTarget.querySelector('.address')
		let range = document.createRange()
		range.selectNodeContents(el)
		let selection = window.getSelection()
		selection.removeAllRanges()
		selection.addRange(range)
		document.execCommand('copy')
		// selection.removeAllRanges()

		this.react()
	}

	react(){
		this.reactTimeout && clearTimeout(this.reactTimeout)

		this.div.classList.add('copied')
		this.reactTimeout = setTimeout(()=>{
			this.div.classList.remove('copied')
		}, 200)
	}
}