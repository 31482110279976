import './scores.scss'


class ScoreItem{
	constructor(props){
		this.title = props.title
	}
}

export default class Scores{
	constructor(props){
		this.props = props
		this.items = props.items

		this.div = document.createElement('table')
		this.div.className = 'score-list'


		//header
		this.head = document.createElement('thead')

		let headerItem = document.createElement('tr')
		headerItem.innerHTML = `
			<th>&nbsp;</th>
			<th>Name</th>
			<th>Date</th>
			<th>Score</th>
		`

		this.head.appendChild(headerItem)

		//body
		this.body = document.createElement('tbody')


		this.items.forEach((item, index)=>{
			let itemDiv = document.createElement('tr')
			itemDiv.className = `score-item`

			itemDiv.innerHTML = `
				<td>${index+1}</td>
				<td>${item.name}</td>
				<td>${item.date}</td>
				<td>${item.score}</td>
			`

			this.body.appendChild(itemDiv)
		})

		this.div.appendChild(this.head)
		this.div.appendChild(this.body)


		return this.div
	}
}
