// import DownloadLink from './download-link.js'
import CryptoBlock from './crypto-block.js'
import Accordion from './accordion.js'
import Scores from './scores.js'
import packageJSON from '../../package.json'
import track from './../track.js'
import api from './../api.js'

function start(){
	let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
	if(iOS){
		document.getElementsByTagName('body')[0].classList.add('ios')
		console.log('yay')
	}

	//add download links
	// let downloadsDiv = document.getElementById('download-links')
	//iterate info from json about already built apps
	/*
	packageJSON.built.forEach((build, index)=>{
		downloadsDiv.appendChild(
			new DownloadLink({
				title: build.fileName,
				subtitle: `download for ${build.platform}`,
				size: `${build.size} MB`,
				platform: build.platform
			})
		)
	})
	*/

	//add crypto donation links
	let cryptdonationsDiv = document.getElementById('crypto-wallets')
	//iterate info about wallets from package json
	packageJSON.cryptoWallets.forEach((wallet, index)=>{
		cryptdonationsDiv.appendChild(
			new CryptoBlock({
				coin: wallet.coin,
				address: wallet.address
			})
		)
	})

	let releaseNotesTarget = document.getElementById('release-notes')
	let releaseNotesData = packageJSON["release-notes"]
	releaseNotesTarget.appendChild(
		new Accordion({
			items: releaseNotesData
		})
	)

	track('landed')

	//some other tracking stuff
	document.getElementById('ninja-link').onclick = ()=>{
		track('ninja link pressed')
	}

	//scores
	let scoresTargetDiv = document.getElementById('high-scores')

	api.getScores()
	.then((response)=>{
		scoresTargetDiv.appendChild(
			new Scores({items: response.data})
		)
	})
	.catch((err)=>{
		console.warn(err)
	})
}

start()