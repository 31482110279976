import axios from 'axios'
let spaceServer = (process.env.NODE_ENV === 'development') ? 'http://localhost:4444' : 'https://spacewarz.rocks'

export default class Api{
	static getScores(){
		return axios.get(`${spaceServer}/scores`)
	}

	static score(hush){
		return axios({
			method: 'post',
			url: `${spaceServer}/score-offering`,
			data: {
				sacrifice: hush
			}
		})
	}

	static ifItsABetterScore(score){
		let gotInsight = new Promise((resolve, reject)=>{
			Api.getScores()
			.then((response)=>{
				if(response.data[6] && (response.data[6].score > score)){
					reject('not good enough')
				}else{
					resolve('good enough score')
				}
			})
			.catch(()=>{
				reject('could not get scores, check network')
			})
		})

		return gotInsight
	}
}

export class NativeMobileApi{
	static vibrate(millis){
		if(window.android){
			android.vibrate(millis)
		}else{
			try {
				webkit.messageHandlers.vibrate.postMessage(millis)
			} catch(err) {
				// console.log('The iOS native context does not exist yet')
			}
		}
	}

	static callPurchaseInfo(){
		switch(game.platform){
			case 'ios':
				try {
					webkit.messageHandlers.purchases.postMessage('go purchases!')
				} catch(err) {
					NativeMobileApi.log('Failed to ask for purchases on iOS :(')
				}
				break
			case 'android':
				console.log('android cant handle this yet')
				break
			default:
				break
		}
		//call native stuff for purchases
	}

	static purchase(id){
		switch(game.platform){
			case 'ios':
				try {
					webkit.messageHandlers.purchase.postMessage(id)
				} catch(err) {
					NativeMobileApi.log('Failed to ask for purchases on iOS :(')
				}
				break
			case 'android':
				console.log('android cant handle this yet')
				break
			default:
				break
		}
	}

	static log(string){
		try {
			webkit.messageHandlers && webkit.messageHandlers.logThing.postMessage(string)
		} catch(err) {
			// console.log('The iOS native context does not exist yet')
		}
	}
}